import { Subscription } from 'rxjs';
import { VerlockerService } from 'src/app/services/_core/verlocker/verlocker.service';
import { BhAnalyticsService } from './services/_core/bhanalytics/bhanalytics.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Keyboard } from '@capacitor/keyboard';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  updateInterval = null;
  displayingVerlocker = false;
  loadingSub: Subscription = null;
  isLoading = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private notifications: NotificationsService,
    private analytics: BhAnalyticsService,
    private verlockerService: VerlockerService
  ) {
    this.initializeApp();
    this.subscribeToLoader();
    this.checkVersion();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      Keyboard.setAccessoryBarVisible({ isVisible: true});
      this.analytics.initAnalytics();
    });
  }

  subscribeToLoader() {
    this.loadingSub = this.notifications.isLoadingBehaviorSubject.subscribe(val => {
      this.isLoading = val;
    });
  }

  checkVersion() {
    if (!this.updateInterval) {
      this.verlockerService.checkVersion().toPromise();
      this.updateInterval = setInterval(() => {
        if (!this.verlockerService.displayingVerlocker) {
          this.verlockerService.checkVersion().toPromise();
        } else {
        }
      }, 120000);
    }
  }

}
