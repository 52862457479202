import { LoginRouteGuardService } from 'src/app/services/_core/login-route-guard/login-route-guard.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BhAnalyticsRouteGuardService } from './services/_core/bh-analytics-route-guard/bh-analytics-route-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  // {
  //   path: '**',
  //   redirectTo: '/login'
  // },
  {
    path: 'login',
    pathMatch: 'full',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'help-forgot-pwd',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () =>
      import('./pages/_core/help-forgot-pwd/help-forgot-pwd.module').then(m => m.HelpForgotPwdPageModule)
  },
  {
    path: 'share',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/share/share.module').then(m => m.SharePageModule)
  },
  {
    path: 'help',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/help/help.module').then(m => m.HelpPageModule)
  },
  {
    path: 'help-no-pin',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: './pages/_core/help-no-pin/help-no-pin.module#HelpNoPinPageModule'
  },
  {
    path: 'errors',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: './pages/_core/errors/errors.module#ErrorsPageModule'
  },
  {
    path: 'errors-detail',
    canActivate: [BhAnalyticsRouteGuardService],
    loadChildren: './pages/_core/errors-detail/errors-detail.module#ErrorsDetailPageModule'
  },
  {
    path: 'check-for-updates',
    canActivate: [BhAnalyticsRouteGuardService],
    data: { pageName: 'Check for Updates', roles: [] },
    loadChildren: () => import('./pages/_core/check-for-updates/check-for-updates.module').then( m => m.CheckForUpdatesPageModule)
  },
  {
    path: 'about',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'my-account',
    canActivate: [LoginRouteGuardService, BhAnalyticsRouteGuardService],
    loadChildren: () => import('./pages/_core/my-account/my-account.module').then( m => m.MyAccountPageModule)
  },
  {
    path: 'bh-input-text-editor',
    loadChildren: () => import('./components/_core/bh-input-text-editor/bh-input-text-editor.module').then( m => m.BhInputTextEditorPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/_core/feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: 'health-profile',
    loadChildren: () => import('./pages/health-profile/health-profile.module').then( m => m.HealthProfilePageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'request-appt',
    loadChildren: () => import('./pages/request-appt/request-appt.module').then( m => m.RequestApptPageModule)
  },
  {
    path: 'preferences',
    loadChildren: () => import('./pages/preferences/preferences.module').then( m => m.PreferencesPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
